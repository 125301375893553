import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import firebase from "firebase/compat/app";
import ViewImage from "../GlobalComponents/ViewImage";

export default function ViewReport(props) {
  const [open, setOpen] = useState(false);
  const db = firebase.firestore();
  const [reportData, setReportData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [viewImage, setViewImage] = useState(false);
  const [media, setMedia] = useState(null);

  useEffect(() => {
    setOpen(props.open);
    if (props.open && props.viewDetailsData) {
      // gotta find the report sent to customer
      if (props.viewDetailsData.reportType !== "job") {
        try {
          db.collection("Customers")
            .doc(props.viewDetailsData.customerId)
            .collection("PoolReports")
            .where("businessId", "==", props.viewDetailsData.businessId)
            .where("reportId", "==", props.viewDetailsData.reportId)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.docs.length > 0) {
                const data = querySnapshot.docs[0].data();
                setReportData(data);
              } else {
                setReportData(null);
              }
            });

          db.collection("Customers")
            .doc(props.viewDetailsData.customerId)
            .get()
            .then((doc) => {
              if (doc.exists) {
                setCustomerData(doc.data());
              } else {
                setCustomerData(null);
              }
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          db.collection("Customers")
            .doc(props.viewDetailsData.customerId)
            .collection("PoolReports")
            .where("businessId", "==", props.viewDetailsData.businessId)
            .where("reportId", "==", props.viewDetailsData.reportId)
            .get()
            .then((querySnapshot) => {
              if (querySnapshot.docs.length > 0) {
                const data = querySnapshot.docs[0].data();
                setReportData(data);
              } else {
                setReportData(null);
              }
            });

          db.collection("Customers")
            .doc(props.viewDetailsData.customerId)
            .get()
            .then((doc) => {
              if (doc.exists) {
                setCustomerData(doc.data());
              } else {
                setCustomerData(null);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      setReportData(null);
    }
  }, [props.open, props.viewDetailsData]);

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleViewImage = (media) => {
    setMedia(media);
    setViewImage(true);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />\
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto hideScroll">
                      <div className="bg-black py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          {customerData && (
                            <Dialog.Title className="text-lg font-medium text-white">
                              {(
                                customerData.customerFirstName +
                                " " +
                                customerData.customerLastName
                              ).replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                letter.toUpperCase()
                              )}
                            </Dialog.Title>
                          )}

                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          {props.viewDetailsData &&
                            props.viewDetailsData.subscriptionTitle && (
                              <p className="text-sm text-white">
                                {
                                  props.viewDetailsData.subscriptionTitle.split(
                                    ","
                                  )[0]
                                }
                              </p>
                            )}
                        </div>
                      </div>

                      {reportData && (
                        <div
                          className="px-6"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            paddingBottom: 80,
                            marginTop: 20,
                          }}
                        >
                          <p
                            style={{
                              fontSize: 18,
                              fontWeight: 600,
                              marginLeft: 4,
                              marginTop: 4,
                            }}
                          >
                            {dayjs
                              .unix(reportData.reportDate?.seconds)
                              .format("DD MMM, YYYY - h:mm A")}{" "}
                            Report
                          </p>

                          <div>
                            <p
                              style={{
                                fontSize: 18,
                                fontWeight: 600,
                                marginTop: 22,
                                marginLeft: 4,
                              }}
                            >
                              Pictures & Videos
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginLeft: -5,
                                  overflowX: "scroll",
                                }}
                              >
                                {reportData.reportObjects
                                  ?.filter((a) => a.type === "picsVids")[0]
                                  ?.value.map((media, index) => (
                                    <div
                                      key={index}
                                      onClick={() => handleViewImage(media)}
                                      id={`media-container-${index}`}
                                      style={{
                                        display: "inline-block",
                                        minWidth: 160,
                                        minHeight: 160,
                                        backgroundColor: "#fff",
                                        margin: 10,
                                        boxShadow:
                                          "0px 4px 4.65px rgba(0,0,0,0.3)",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        src={media}
                                        alt={`media-${index}`}
                                        onError={(e) => {
                                          const parent =
                                            document.getElementById(
                                              `media-container-${index}`
                                            );

                                          if (parent) {
                                            parent.style.display = "none"; // Hide the parent div
                                          }
                                        }}
                                        style={{
                                          minWidth: 160,
                                          minHeight: 160,
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>

                          {reportData.reportObjects?.filter(
                            (a) => a.type === "readings"
                          )[0] && (
                            <div
                              style={{
                                borderBottom: "1.4px solid #D9D9D9",
                                marginTop: "6%",
                                borderRadius: 10,
                              }}
                            ></div>
                          )}

                          <div>
                            {reportData.reportObjects?.filter(
                              (a) => a.type === "readings"
                            )[0] && (
                              <p
                                style={{
                                  fontSize: 18,
                                  fontWeight: 600,
                                  marginTop: 32,
                                  marginLeft: 4,
                                }}
                              >
                                Readings
                              </p>
                            )}

                            {reportData.reportObjects
                              ?.filter((a) => a.type === "readings")[0]
                              ?.value.map((reading, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginLeft: 4,
                                    marginTop: 18,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 16,
                                        fontWeight: 500,
                                        margin: 0,
                                      }}
                                    >
                                      {reading.name}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: 14,
                                        marginLeft: "auto",
                                        marginRight: "4%",
                                      }}
                                    >
                                      {reading.value}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>

                          <div
                            style={{
                              borderBottom: "1.4px solid #D9D9D9",
                              marginTop: "6%",
                              borderRadius: 10,
                            }}
                          ></div>

                          <div>
                            <p
                              style={{
                                fontSize: 18,
                                fontWeight: 600,
                                marginTop: 22,
                                marginLeft: 4,
                              }}
                            >
                              Message
                            </p>

                            {reportData?.reportObjects?.find(
                              ({ type }) => type === "customerMessage"
                            )?.value && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginLeft: 4,
                                  marginTop: 10,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 500,
                                      margin: 0,
                                    }}
                                  >
                                    {
                                      reportData?.reportObjects?.find(
                                        ({ type }) => type === "customerMessage"
                                      )?.value
                                    }
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>

                          {reportData.reportObjects?.find(
                            ({ type }) => type === "chemicalsAdded"
                          ) && (
                            <div
                              style={{
                                borderBottom: "1.4px solid #D9D9D9",
                                marginTop: "4%",
                                borderRadius: 10,
                              }}
                            ></div>
                          )}

                          <div>
                            {reportData.reportObjects?.find(
                              ({ type }) => type === "chemicalsAdded"
                            ) && (
                              <p
                                style={{
                                  fontSize: 18,
                                  fontWeight: 600,
                                  marginTop: 22,
                                  marginLeft: 4,
                                }}
                              >
                                Chemicals Added
                              </p>
                            )}

                            {reportData.reportObjects
                              ?.find(({ type }) => type === "chemicalsAdded")
                              ?.value.map((chemical, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginLeft: 4,
                                    marginTop: 18,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 16,
                                        fontWeight: 500,
                                        margin: 0,
                                      }}
                                    >
                                      {chemical.chemicalData?.name}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: 14,
                                        marginLeft: "auto",
                                        marginRight: "4%",
                                      }}
                                    >
                                      {chemical.value}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-shrink-0 justify-start px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={handleClose}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                  <ViewImage
                    media={media}
                    open={viewImage}
                    setOpen={setViewImage}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
