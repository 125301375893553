import React from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { HiOutlinePencil } from "react-icons/hi";
import { MdPlace } from "react-icons/md";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";

const JobStopsNew = (props) => {
  const stops = props.jobStops || [];
  const db = firebase.firestore();
  const searchTerm = "";

  const viewReport = async (stop) => {
    console.log(stop);

    const querySnapshot = await db
      .collection("Customers")
      .doc(stop.customerData.customerId)
      .collection("PoolReports")
      .where("businessId", "==", stop.businessId)
      .where("reportJobId", "==", stop.jobId)
      .get();

    const reports = querySnapshot.docs.map((doc) => doc.data());

    const exactReport = reports.find(
      (report) =>
        dayjs.unix(report.reportDate.seconds).format("MM/DD/YYYY") ===
        stop.stopDate
    );

    console.log(exactReport);

    if (exactReport) {
      props.handleViewReport(exactReport);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        alignSelf: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      {/* Search Bar */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: "2px solid #D9D9D9",
            borderRadius: "8px",
            height: "50px",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <input
            type="text"
            placeholder="Search"
            style={{
              height: "100%",
              flex: 1,
              paddingLeft: "10px",
              paddingRight: "10px",
              fontSize: "16px",
              border: "none",
              outline: "none",
            }}
          />
        </div>
      </div>

      {/* Stops List */}
      <div style={{ width: "100%", height: "60%", overflowY: "auto" }}>
        {stops
          .filter((a) =>
            searchTerm.length === 0
              ? true
              : a.stopNickname.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((value) => (
            <div
              key={value.stopId}
              style={{
                border: "2px solid #D9D9D9",
                width: "100%",
                height: "130px",
                marginTop: "16px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "6px",
                borderRadius: "10px",
              }}
            >
              <div style={{ marginLeft: "2%" }}>
                <img
                  style={{
                    width: "68px",
                    height: "68px",
                    minHeight: "68px",
                    minWidth: "68px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  src="https://firebasestorage.googleapis.com/v0/b/symbri.appspot.com/o/placeholder.jpeg?alt=media&token=0c577d79-ec85-40f7-a2a2-7962611740ef"
                  alt="Stop Placeholder"
                />
              </div>
              <div style={{ marginLeft: "2%" }}>
                <p style={{ fontSize: "18px", fontWeight: "700", margin: "0" }}>
                  {value.stopNickname || value.stopTitle}
                </p>

                {value.stopDate === "none" && (
                  <p
                    style={{ marginTop: "4px", fontWeight: "500", margin: "0" }}
                  >
                    Unscheduled
                  </p>
                )}
                {value.stopDate !== "none" && (
                  <p
                    style={{ marginTop: "4px", fontWeight: "500", margin: "0" }}
                  >
                    {value.contractor?.name}
                  </p>
                )}

                <p style={{ marginTop: "4px", fontWeight: "500", margin: "0" }}>
                  {value.stopDate}
                </p>

                <p style={{ marginTop: "4px", fontWeight: "500", margin: "0" }}>
                  {props.jobData.serviceLocation.address.split(",")[0]}
                </p>
              </div>
              {(value.completedDates?.length === 0 ||
                !value.completedDates) && (
                <div className="flex ml-auto mr-4">
                  <HiOutlinePencil
                    onClick={() => props.handleEditStopClick(value)}
                    className="cursor-pointer"
                    size={24}
                  />
                  <FaRegTrashAlt
                    onClick={() => props.handleDeleteJobStop(value)}
                    className="ml-3 cursor-pointer"
                    size={24}
                  />
                </div>
              )}
              {value.completedDates && value.completedDates.length !== 0 && (
                <div
                  onClick={() => viewReport(value)}
                  className="flex ml-auto mr-4 cursor-pointer"
                >
                  View Report
                </div>
              )}
            </div>
          ))}

        {stops.length === 0 && (
          <div
            style={{
              width: "100%",
              height: "80%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MdPlace size={180} />
            <p style={{ color: "black", fontSize: "18px", marginTop: "10px" }}>
              You have not created any stops
            </p>
            <button
              onClick={() => props.setAddStopDisplay(true)}
              style={{
                height: "60px",
                width: "91%",
                backgroundColor: "black",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                borderRadius: "10px",
                marginTop: "20px",
                fontWeight: "600",
                fontSize: "18px",
                border: "none",
                cursor: "pointer",
              }}
            >
              Create Stop
            </button>
          </div>
        )}
      </div>

      {/* Create Stop Button */}
      {stops.length > 0 && (
        <button
          onClick={() => props.setAddStopDisplay(true)}
          style={{
            height: "60px",
            width: "100%",
            backgroundColor: "black",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            borderRadius: "10px",
            marginTop: "auto",
            fontWeight: "600",
            fontSize: "18px",
            border: "none",
            cursor: "pointer",
            marginTop: "20px",
          }}
        >
          Create Stop
        </button>
      )}
    </div>
  );
};

export default JobStopsNew;
