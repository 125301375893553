import { useEffect, useState } from "react";
import SubscriptionDetailsTabs from "./SubscriptionDetailsTabs";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";

export default function SubscriptionDetails(props) {
  const db = firebase.firestore();
  const subscription = props.subscription;
  const [selectedTab, setSelectedTab] = useState("Customers");
  const [customers, setCustomers] = useState([]);
  const [fromDate, setFromDate] = useState(
    dayjs().subtract(31, "days").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [sortBy, setSortBy] = useState("name");

  const [sortConfig, setSortConfig] = useState({
    field: "owner",
    direction: "asc",
  });

  const stats = [
    {
      name: "Monthly Earnings",
      stat: "$" + customers.length * subscription.subscriptionPrice,
    },
    { name: "Total Customers", stat: customers.length },
    { name: "Avg. Stop Time", stat: "8 Minutes" },
  ];

  const handleSort = (field) => {
    setSortConfig((prev) => {
      if (prev.field === field) {
        return { field, direction: prev.direction === "asc" ? "desc" : "asc" };
      } else {
        return { field, direction: "asc" };
      }
    });
  };

  const calculateMetrics = async (data) => {
    try {
      // Convert fromDate and toDate to Date objects.
      // (If you want to include the entire toDate day, you might adjust the end time accordingly.)
      const startDate = new Date(fromDate); // starts at 00:00:00 of fromDate
      const endDate = new Date(toDate + "T23:59:59.999"); // ends at 23:59:59.999 of toDate

      const reportsRef = await db
        .collection("Customers")
        .doc(data.customerId)
        .collection("PoolReports")
        .where("businessId", "==", data.businessId)
        .where("subscriptionId", "==", subscription.subscriptionId)
        // Add the date range filters:
        .where("reportDate", ">=", startDate)
        .where("reportDate", "<=", endDate)
        .get();

      const reports = reportsRef.docs.map((doc) => doc.data());

      // If you have additional filtering by service location:
      const serviceLocationReports = reports.filter(
        (report) => report.reportServiceLocationId === data.serviceLocationId
      );

      // Group the data by month (based on the provided fromDate/toDate).
      const lastMonths = (() => {
        const from = dayjs(fromDate).startOf("month");
        const to = dayjs(toDate).startOf("month");
        const months = [];
        let current = from;

        while (current.isBefore(to) || current.isSame(to, "month")) {
          months.push(current.format("MMMM"));
          current = current.add(1, "month");
        }

        return months;
      })();

      const monthsData = await Promise.all(
        lastMonths.map(async (month) => {
          // Filter reports for this month.
          const monthReports = serviceLocationReports.filter(
            (report) =>
              dayjs(report.reportDate.seconds * 1000).format("MMMM") === month
          );

          // Extract chemicalsAdded objects from each report.
          const chemicals = monthReports
            .map((report) =>
              report.reportObjects.find((a) => a.type === "chemicalsAdded")
            )
            .filter(Boolean);

          const chemicalObjects = chemicals.flat();

          // Calculate costs for each chemical.
          const chemicalCosts = await Promise.all(
            chemicalObjects.map(async (chemical) => {
              const chemsPromises = chemical.value.map(async (chem) => {
                const chemicalId = chem.chemicalData.chemicalId;

                const chemicalRef = await db
                  .collection("Businesses")
                  .doc(data.businessId)
                  .collection("Chemicals")
                  .doc(chemicalId)
                  .get();

                const chemicalData = chemicalRef.data();

                return {
                  chemicalName: chemicalData.chemicalName,
                  value:
                    parseFloat(
                      chemicalData.chemicalPricePerDose.replace("$", "")
                    ) * chem.value || 0,
                };
              });

              return Promise.all(chemsPromises);
            })
          );

          const flatChemicalCosts = chemicalCosts.flat();

          // Aggregate chemical costs by name.
          const chemicalsArray = flatChemicalCosts.reduce((acc, chemical) => {
            const existingChemical = acc.find(
              (c) => c.chemicalName === chemical.chemicalName
            );
            if (existingChemical) {
              existingChemical.value += isNaN(chemical.value)
                ? 0
                : chemical.value;
            } else {
              acc.push({
                chemicalName: chemical.chemicalName,
                value: isNaN(chemical.value) ? 0 : chemical.value,
              });
            }
            return acc;
          }, []);

          return {
            name: month,
            chemicals: chemicalsArray,
          };
        })
      );

      const totalCosts = monthsData.reduce((acc, month) => {
        const monthTotal = month.chemicals.reduce(
          (acc, chemical) => acc + (isNaN(chemical.value) ? 0 : chemical.value),
          0
        );
        return acc + monthTotal;
      }, 0);

      return { monthsData, totalCosts };
    } catch (error) {
      console.error("Error in calculateMetrics:", error);
      return { monthsData: [], totalCosts: 0 };
    }
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const customerSnapshot = await db
          .collection("Customers")
          .where("businessIds", "array-contains", props.selectedBusiness)
          .get();

        const customersArray = [];
        const serviceLocationPromises = [];

        for (const doc of customerSnapshot.docs) {
          const customerDoc = doc.data();

          const serviceLocationPromise = db
            .collection("Customers")
            .doc(customerDoc.customerId)
            .collection("ServiceLocations")
            .where("businessId", "==", props.selectedBusiness)
            .get()
            .then(async (serviceLocationSnapshot) => {
              const locationPromises = serviceLocationSnapshot.docs.map(
                async (serviceDoc) => {
                  const serviceLocationDoc = serviceDoc.data();

                  const foundSubscription =
                    serviceLocationDoc.subscriptions.find(
                      (a) => a.subscriptionId === subscription.subscriptionId
                    );

                  if (foundSubscription) {
                    const cost = await calculateMetrics(serviceLocationDoc);
                    customersArray.push({
                      serviceLocationId: serviceLocationDoc.serviceLocationId,
                      serviceLocationOwnerName: `${customerDoc.customerFirstName} ${customerDoc.customerLastName}`,
                      serviceLocationAddress: serviceLocationDoc.address,
                      serviceLocationName: serviceLocationDoc.name,
                      subscription: foundSubscription,
                      cost,
                    });
                  }
                }
              );

              await Promise.all(locationPromises);
            });

          serviceLocationPromises.push(serviceLocationPromise);
        }

        await Promise.all(serviceLocationPromises);

        console.log(customersArray);
        setCustomers(customersArray);
      } catch (error) {
        console.error("Error fetching customers: ", error);
      }
    };

    fetchCustomers();
  }, [props.selectedBusiness, fromDate, toDate]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Customers Enrolled: {customers.length}
          </h1>
          <div className="flex flex-wrap items-end gap-4 mb-4 mt-6">
            <div>
              <label className="block mb-1">Chemicals From:</label>
              <input
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                className="border border-gray-300 rounded px-2 py-1"
              />
            </div>
            <div>
              <label className="block mb-1">Chemicals To:</label>
              <input
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className="border border-gray-300 rounded px-2 py-1"
              />
            </div>
          </div>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <SubscriptionDetailsTabs setSelectedTab={setSelectedTab} />
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {selectedTab === "Metrics" && (
                <div style={{ padding: 20, backgroundColor: "#F9FAFB" }}>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Last 30 days
                  </h3>
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                    {stats.map((item) => (
                      <div
                        key={item.name}
                        className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
                      >
                        <dt className="truncate text-sm font-medium text-gray-500">
                          {item.name}
                        </dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                          {item.stat}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
              )}

              {selectedTab === "Customers" && (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        <div className="flex items-center">
                          Owner
                          <button
                            onClick={() => handleSort("owner")}
                            className="ml-1"
                          >
                            {sortConfig.field === "owner" ? (
                              sortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="h-5 w-5 text-black" />
                              ) : (
                                <ChevronDownIcon className="h-5 w-5 text-black" />
                              )
                            ) : (
                              <ChevronUpIcon className="h-5 w-5 text-black" />
                            )}
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="flex items-center">
                          Address
                          <button
                            onClick={() => handleSort("address")}
                            className="ml-1"
                          >
                            {sortConfig.field === "address" ? (
                              sortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="h-5 w-5 text-black" />
                              ) : (
                                <ChevronDownIcon className="h-5 w-5 text-black" />
                              )
                            ) : (
                              <ChevronUpIcon className="h-5 w-5 text-black" />
                            )}
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-1 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="flex items-center">
                          Subscription Price
                          <button
                            onClick={() => handleSort("subPrice")}
                            className="ml-1"
                          >
                            {sortConfig.field === "subPrice" ? (
                              sortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="h-5 w-5 text-black" />
                              ) : (
                                <ChevronDownIcon className="h-5 w-5 text-black" />
                              )
                            ) : (
                              <ChevronUpIcon className="h-5 w-5 text-black" />
                            )}
                          </button>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        <div className="flex items-center">
                          Chemical Cost
                          <button
                            onClick={() => handleSort("chemCost")}
                            className="ml-1"
                          >
                            {sortConfig.field === "chemCost" ? (
                              sortConfig.direction === "asc" ? (
                                <ChevronUpIcon className="h-5 w-5 text-black" />
                              ) : (
                                <ChevronDownIcon className="h-5 w-5 text-black" />
                              )
                            ) : (
                              <ChevronUpIcon className="h-5 w-5 text-black" />
                            )}
                          </button>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {customers
                      .sort((a, b) => {
                        let compare = 0;
                        if (sortConfig.field === "owner") {
                          compare = a.serviceLocationOwnerName.localeCompare(
                            b.serviceLocationOwnerName
                          );
                        } else if (sortConfig.field === "address") {
                          compare = a.serviceLocationAddress.localeCompare(
                            b.serviceLocationAddress
                          );
                        } else if (sortConfig.field === "subPrice") {
                          compare =
                            a.subscription.subscriptionPrice -
                            b.subscription.subscriptionPrice;
                        } else if (sortConfig.field === "chemCost") {
                          compare = a.cost.totalCosts - b.cost.totalCosts;
                        }
                        return sortConfig.direction === "asc"
                          ? compare
                          : -compare;
                      })
                      .map((customer) => (
                        <tr key={customer.serviceLocationId}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {customer.serviceLocationOwnerName.replace(
                              /(^\w{1})|(\s+\w{1})/g,
                              (letter) => letter.toUpperCase()
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {customer.serviceLocationAddress.substring(0, 30) +
                              "..."}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            ${customer.subscription.subscriptionPrice}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            ${customer.cost.totalCosts.toFixed(2)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
