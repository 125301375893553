import { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import SelectSubscription from "./SelectSubscription";
import firebase from "firebase/compat/app";
import { generateBusinessId } from "../Tools/generateBusinessId";
import dayjs from "dayjs";
import AutoComplete from "react-google-autocomplete";
import { toast } from "react-toastify";
import { Rings } from "react-loader-spinner";

export default function CustomerAddServiceLocation(props) {
  const [open, setOpen] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const db = firebase.firestore();
  const [newPoolName, setNewPoolName] = useState("");
  const [newPoolAddress, setNewPoolAddress] = useState("");
  const [newPoolPropertyAccessCode, setNewPoolPropertyAccessCode] =
    useState("");
  const [newPoolMonthlyPrice, setNewPoolMonthlyPrice] = useState(0);
  const [newPoolAggressivePets, setNewPoolAggressivePets] = useState("");
  const customerData = props.selectedCustomer;
  const [addedSubscriptions, setAddedSubscriptions] = useState({});
  const [loading, setLoading] = useState(false);

  const handleAddNewPoolClick = async () => {
    if (newPoolName === "" || newPoolName.trim().length === 0) {
      toast.warn("Service location name field is required!");
      return;
    } else if (newPoolAddress === "" || newPoolAddress.trim().length === 0) {
      toast.warn("Service location address field is required!");
      return;
    } else if (
      newPoolMonthlyPrice === 0 ||
      newPoolMonthlyPrice.trim().length === 0
    ) {
      toast.warn("Service location monthly price field is required");
      return;
    }

    const serviceLocationId = generateBusinessId();
    setLoading(true);
    console.log(customerData.customerId);

    // check if customer already has a service location at this address

    const serviceLocationCheck = await db
      .collection("Customers")
      .doc(customerData.customerId)
      .collection("ServiceLocations")
      .where("businessId", "==", props.businessId)
      .where("address", "==", newPoolAddress)
      .get();

    if (serviceLocationCheck.docs.length > 0) {
      setLoading(false);
      toast.warn(
        "This customer already has a service location at this address!"
      );
      return;
    }

    // check if added suubscription is empty object

    let editedSubscription = addedSubscriptions;

    if (Object.keys(addedSubscriptions).length !== 0) {
      const subscriptionStopId = generateBusinessId();

      editedSubscription.subscriptionStopId = subscriptionStopId;
    }

    db.collection("Customers")
      .doc(customerData.customerId)
      .collection("ServiceLocations")
      .doc(serviceLocationId)
      .set({
        routes: [],
        baseCyaLevels: "Pending Inspection",
        lastCleaning: "Pending Inspection",
        tds: "Pending Inspection",
        phosphates: "Pending Inspection",
        salt: "Pending Inspection",
        lastWaterChange: "Pending Inspection",
        filter: "Pending Inspection",
        pumpType: "Pending Inspection",
        chlorinator: "Pending Inspection",
        polaris: "Pending Inspection",
        borates: "Pending Inspection",
        gallons: "Pending Inspection",
        spa: "Pending Inspection",
        automation: "Pending Inspection",
        travelFee: "Pending Inspection",
        poolSurface: "Pending Inspection",
        name: newPoolName,
        address: newPoolAddress,
        serviceLocationId: serviceLocationId,
        businessId: props.businessId,
        propertyAccessCodes: newPoolPropertyAccessCode,
        monthlyPrice: newPoolMonthlyPrice,
        pets: newPoolAggressivePets,
        notes: [],
        notesFromCustomer: "",
        photos: [],
        subscriptions:
          Object.keys(addedSubscriptions).length !== 0
            ? [editedSubscription]
            : [],
        customerId: customerData.customerId,
      })
      .then(async () => {
        setLoading(false);
        props.setOpen(false);
        console.log(`FINISHED FOR CUSTOMER: ${customerData.customerId}`);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error writing to Firestore:", error);
      });
  };

  useEffect(() => {
    setOpen(props.open);

    if (props.open) {
      db.collection("Businesses")
        .doc(props.businessId)
        .collection("Subscriptions")
        .get()
        .then((snapshot) => {
          let subs = [];
          for (let index = 0; index < snapshot.docs.length; index++) {
            const doc = snapshot.docs[index].data();
            subs.push(doc);
          }

          setSubscriptions(subs);
        });
    }
  }, [props.open]);

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {" "}
                            New Service Location{" "}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Get started by filling in the information below to
                            add a new service location to this customer.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Name{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setNewPoolName(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Address{" "}
                              </label>
                              <div className="mt-1">
                                <AutoComplete
                                  options={{
                                    types: ["address"],
                                  }}
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  apiKey={
                                    process.env.REACT_APP_GOOGLE_MAPS_API_TOKEN
                                  }
                                  onPlaceSelected={(value) =>
                                    setNewPoolAddress(value.formatted_address)
                                  }
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Access Code{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setNewPoolPropertyAccessCode(
                                      change.target.value
                                    )
                                  }
                                  type="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Monthly Price{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setNewPoolMonthlyPrice(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Pets{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setNewPoolAggressivePets(
                                      change.target.value
                                    )
                                  }
                                  type="text"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Subscription{" "}
                              </label>
                              <div className="mt-1">
                                <SelectSubscription
                                  businessId={props.businessId}
                                  setAddedSubscriptions={setAddedSubscriptions}
                                  subscriptions={subscriptions}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose(false)}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleAddNewPoolClick}
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {loading ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>Loading </div>
                            <Rings
                              height={22}
                              width={22}
                              color="white"
                              arialLabel="loading-indicator"
                            />
                          </div>
                        ) : (
                          "Add"
                        )}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
