import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import NewJob from "./NewJob";
import ViewJob from "./ViewJob";
import { toast } from "react-toastify";
import JobsButton from "./JobsButton";
import Categories from "./Categories";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import dayjs from "dayjs";
import ViewJobReport from "./ViewJobReport";

export default function Jobs(props) {
  const selectedBusiness = props.businessId;
  const db = firebase.firestore();
  const userPermissions = props.userPermissions;
  const [allJobs, setAllJobs] = useState([]);
  const [createJobDisplay, setCreateJobDisplay] = useState(false);
  const [selectedJob, setSelectedJob] = useState();
  const [editJobDisplay, setEditJobDisplay] = useState(false);
  const [categoriesDisplay, setCategoriesDisplay] = useState(false);
  const [sortBy, setSortBy] = useState("customer");
  const [allCategories, setAllCategories] = useState([]);
  const [viewReportOpen, setViewReportOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [serviceLocation, setServiceLocation] = useState();
  const [selectedReport, setSelectedReport] = useState();
  const [selectedCategory, setSelectedCategory] = useState({
    title: "all",
    color: "none",
  });
  const searchTerm = props.searchTerm;
  const jobRouteData = props.jobRouteData;

  useEffect(() => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("JobCategories")
      .doc("Categories")
      .onSnapshot((snapshot) => {
        const data = snapshot.data();
        if (data) {
          setAllCategories(data.categories);
        }
      });

    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Jobs")
      .where("jobId", "!=", "")
      .onSnapshot((snapshot) => {
        let jobsArray = [];
        for (let index = 0; index < snapshot.docs.length; index++) {
          const doc = snapshot.docs[index].data();
          jobsArray.push(doc);
        }

        setAllJobs(jobsArray);
      });
  }, []);

  useEffect(() => {
    console.log(jobRouteData);
    if (allJobs.length > 0 && jobRouteData) {
      console.log(allJobs[0]);
      const job = allJobs.find((job) => job.jobId === jobRouteData);
      console.log(job);
      if (job) {
        setSelectedJob(job);
        setEditJobDisplay(true);
        props.setJobRouteData(null);
      }
    }
  }, [allJobs]);

  const handleEditClick = (value) => {
    setSelectedJob(value);
    setEditJobDisplay(true);
  };

  const handleDeleteJob = (job) => {
    if (!userPermissions.includes("Delete Jobs")) {
      toast.warn("You do not have permission to delete jobs.");
      return;
    }

    let scheduledStops = [];
    if (job.stops.length !== 0) {
      for (let index = 0; index < job.stops.length; index++) {
        const stop = job.stops[index];
        if (stop.completedDates && stop.completedDates.length === 0) {
          scheduledStops.push(index);
        }
      }
      if (scheduledStops.length > 0) {
        toast.warn("Cannot delete job with scheduled stops.");
      } else {
        db.collection("Businesses")
          .doc(selectedBusiness)
          .collection("Jobs")
          .doc(job.jobId)
          .delete();
        toast.success("Job has been successfully deleted!");
      }
    } else {
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Jobs")
        .doc(job.jobId)
        .delete();
      toast.success("Job has been successfully deleted!");
    }
  };

  const handleViewReport = (report) => {
    setSelectedReport(report);
    setViewReportOpen(true);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Jobs</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <JobsButton
            setCategoriesDisplay={setCategoriesDisplay}
            setCreateJobDisplay={setCreateJobDisplay}
          />
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 flex"
                    >
                      Customer{" "}
                      {sortBy === "customer" && (
                        <ChevronDownIcon className="h-5 w-5 text-black cursor-pointer" />
                      )}
                      {sortBy !== "customer" && (
                        <ChevronUpIcon
                          onClick={() => setSortBy("customer")}
                          className="h-5 w-5 text-black cursor-pointer"
                        />
                      )}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">
                        Job Title{" "}
                        {sortBy === "jobtitle" && (
                          <ChevronDownIcon className="h-5 w-5 text-black cursor-pointer" />
                        )}
                        {sortBy !== "jobtitle" && (
                          <ChevronUpIcon
                            onClick={() => setSortBy("jobtitle")}
                            className="h-5 w-5 text-black cursor-pointer"
                          />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">
                        Created Date{" "}
                        {sortBy === "createdDate" && (
                          <ChevronDownIcon className="h-5 w-5 text-black cursor-pointer" />
                        )}
                        {sortBy !== "createdDate" && (
                          <ChevronUpIcon
                            onClick={() => setSortBy("createdDate")}
                            className="h-5 w-5 text-black cursor-pointer"
                          />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">
                        <select
                          onChange={(change) =>
                            setSelectedCategory(JSON.parse(change.target.value))
                          }
                          style={{ appearance: "none" }}
                        >
                          <option
                            value={JSON.stringify({
                              title: "all",
                              color: "none",
                            })}
                          >
                            Job Categories
                          </option>
                          {allCategories.map((category) => (
                            <option value={JSON.stringify(category)}>
                              {category.title}
                            </option>
                          ))}
                        </select>{" "}
                        {sortBy === "category" && (
                          <ChevronDownIcon className="h-5 w-5 text-black cursor-pointer" />
                        )}
                        {sortBy !== "category" && (
                          <ChevronUpIcon
                            onClick={() => setSortBy("category")}
                            className="h-5 w-5 text-black cursor-pointer"
                          />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="flex">
                        Status{" "}
                        {sortBy === "status" && (
                          <ChevronDownIcon className="h-5 w-5 text-black cursor-pointer" />
                        )}
                        {sortBy !== "status" && (
                          <ChevronUpIcon
                            onClick={() => setSortBy("status")}
                            className="h-5 w-5 text-black cursor-pointer"
                          />
                        )}
                      </div>
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {allJobs
                    .filter((a) =>
                      searchTerm !== ""
                        ? a.jobTitle
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()) ||
                          (
                            a.customerData.customerFirstName.toLowerCase() +
                            " " +
                            a.customerData.customerLastName.toLowerCase()
                          )
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        : true
                    )
                    .filter((a) =>
                      selectedCategory.title !== "all"
                        ? a.category
                          ? a.category.title === selectedCategory.title
                          : false
                        : true
                    )
                    .sort((a, b) => {
                      if (sortBy === "customer") {
                        return (
                          a.customerData.customerFirstName +
                          " " +
                          a.customerData.customerLastName
                        ).localeCompare(
                          b.customerData.customerFirstName +
                            " " +
                            b.customerData.customerLastName
                        );
                      } else if (sortBy === "jobtitle") {
                        return a.jobTitle.localeCompare(b.jobTitle);
                      } else if (sortBy === "createdDate") {
                        const dateA = dayjs(a.createdDate);

                        const dateB = dayjs(b.createdDate);
                        if (dateA < dateB) {
                          return 1;
                        }
                        if (dateA > dateB) {
                          return -1;
                        }
                        return 0;
                      } else if (sortBy === "category") {
                        return (
                          a.category ? a.category.title : "None"
                        )?.localeCompare(
                          b.category ? b.category.title : "None"
                        );
                      } else if (sortBy === "status") {
                        return a.status.localeCompare(b.status);
                      } else {
                        return 0;
                      }
                    })
                    .map((job) => (
                      <tr key={job.jobId}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {(
                            job.customerData.customerFirstName +
                            " " +
                            job.customerData.customerLastName
                          ).replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                            letter.toUpperCase()
                          )}
                        </td>
                        <td
                          className="whitespace-nowrap overflow-hidden text-ellipsis px-3 py-4 text-sm text-gray-500"
                          style={{ maxWidth: "200px" }}
                        >
                          {job.jobTitle.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {job.createdDate ? job.createdDate : "Unknown"}
                        </td>
                        <td
                          style={{
                            color: job.category
                              ? job.category.color
                              : "#6C7381",
                          }}
                          className="whitespace-nowrap px-3 py-4 text-sm"
                        >
                          {job.category ? job.category.title : "None"}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {job.status.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>

                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex">
                          <div
                            onClick={() => handleEditClick(job)}
                            className="text-indigo-600 hover:text-indigo-900 cursor-pointer ml-auto"
                          >
                            View
                          </div>
                          <div
                            onClick={() => handleDeleteJob(job)}
                            className="text-indigo-600 hover:text-indigo-900 cursor-pointer ml-6"
                          >
                            Delete
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <NewJob
                selectedBusiness={selectedBusiness}
                setOpen={setCreateJobDisplay}
                open={createJobDisplay}
              />
              <Categories
                selectedBusiness={selectedBusiness}
                setOpen={setCategoriesDisplay}
                open={categoriesDisplay}
              />
              <ViewJob
                selectedJob={selectedJob}
                selectedBusiness={selectedBusiness}
                setOpen={setEditJobDisplay}
                open={editJobDisplay}
                setViewReportOpen={setViewReportOpen}
                handleViewReport={handleViewReport}
              />

              <ViewJobReport
                open={viewReportOpen}
                setOpen={setViewReportOpen}
                selectedJob={selectedJob}
                selectedReport={selectedReport}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
